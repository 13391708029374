<vex-secondary-toolbar current>
    <div class="fluxbar">
        <ng-container *ngFor="let item of Modal; index as i">
            <span
                [ngClass]="Flux===item.Modal?'Selected':Flux>item.Modal?'Past':''"
                (click)="Flux>item.Modal?Check.emit(i):''">
                {{item.Name | translate }}
            </span>
            <span *ngIf="i<Modal.length-1" class="Dir">
                >
            </span>
        </ng-container>
    </div>
</vex-secondary-toolbar>