<form>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{'add_user' | translate }}</h2>

    <!--<button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>-->

    <button class="text-secondary" mat-dialog-close mat-icon-button
      type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col" *ngIf="!loading">
    <div style="width: 100%;">
      <p>{{'clients.add.title' | translate}}</p>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>{{'clients.add.search' | translate}}</mat-label>
        <input type="text" placeholder="{{'clients.add.search' | translate}}"
          aria-label="Number" matInput [formControl]="clientsForm"
          [matAutocomplete]="auto" [(ngModel)]="defaults.client_main_id" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let client of filteredOptions | async"
            [value]="client" (onSelectionChange)="ret = client.id">
            {{client.first_name}} {{client.last_name}}
          </mat-option>
        </mat-autocomplete>
        <mat-icon matPrefix svgIcon="mat:person"></mat-icon>
      </mat-form-field>

      <mat-dialog-actions align="end">
        <button color="primary" mat-flat-button [disabled]="ret === null"
          [mat-dialog-close]="{ret: ret, action: 'add'}">{{'confirm' |
          translate}}</button>
        <button color="primary" mat-flat-button
          [mat-dialog-close]="false">{{'no' | translate}}</button>
      </mat-dialog-actions>
    </div>

    <mat-divider></mat-divider>

    <div [formGroup]="form" style="margin: 5% 0 0 0">
      <p>{{'clients.add.create' | translate}}</p>
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6"
        style="margin: 5% 0 0 0">
        <mat-form-field appearance="outline" class="flex-auto">
          <mat-label>{{'name_client' | translate }}</mat-label>
          <input formControlName="name" matInput required type="text"
            [(ngModel)]="defaults.first_name">

        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-auto">
          <mat-label>{{'surname' | translate }}</mat-label>
          <input formControlName="surname" matInput required type="text"
            [(ngModel)]="defaults.last_name">
        </mat-form-field>
      </div>

      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6"
        style="margin: 3% 0 0 0;">
        <mat-form-field appearance="outline" class="flex-auto">
          <input matInput [matDatepicker]="pickerFrom"
            placeholder="{{'birth_date' | translate }}"
            formControlName="fromDate" [max]="minDate"
            [(ngModel)]="defaults.birth_date"
            required>
          <mat-datepicker-toggle matSuffix
            [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom [startAt]="minDate"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-auto">
          <mat-label>{{'langs' | translate }}</mat-label>
          <mat-select [formControl]="languagesControl" multiple>
            <mat-select-trigger>
              <div *ngIf="selectedLanguages.length > 0; else placeholderLangs">
                {{ getSelectedLanguageNames() }}
              </div>
              <ng-template #placeholderLangs>{{'choose_langs' | translate
                }}</ng-template>
            </mat-select-trigger>
            <mat-option *ngFor="let language of filteredLanguages | async"
              [value]="language"
              (onSelectionChange)="toggleSelectionLanguages(language)">
              {{language.name | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end" *ngIf="!loading">
    <button color="primary" mat-flat-button [disabled]="form.invalid"
      (click)="save()">{{'confirm' | translate}}</button>
    <button color="primary" mat-flat-button [mat-dialog-close]="false">{{'no' |
      translate}}</button>
  </mat-dialog-actions>
</form>
