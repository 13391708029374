<div class="body-2 p-6 bg-primary text-primary-contrast">
  <p class="white">{{'today' | translate}}</p>
  <div class="display-1 white">{{ dayName }}</div>
  <div class="display-1 white">{{ date }}</div>
</div>

<mat-divider></mat-divider>

<mat-nav-list>
  <div class="section-header">
    <h3 matSubheader>{{'today_task_list' | translate}}</h3>
    <div class="navigation-buttons" *ngIf="paginatedCurrentTasks.length>0">
      <button mat-icon-button (click)="previousPage()"
        [disabled]="currentPage === 1">
        <mat-icon svgIcon="mat:navigate_before"></mat-icon>
      </button>
      <button mat-icon-button (click)="nextPage()"
        [disabled]="currentPage * pageSize >= todayTasks.length">
        <mat-icon svgIcon="mat:navigate_next"></mat-icon>
      </button>
    </div>
  </div>
  <a [routerLink]="[]" mat-list-item *ngFor="let item of paginatedCurrentTasks">
    <h4 matListItemTitle>{{item.name}}</h4>
    <p matListItemLine>{{ item.date | date: 'dd/MM/YYYY'}} - {{item.time}}</p>
  </a>
  <mat-divider></mat-divider>
  <div class="section-header">
    <h3 matSubheader>{{'all_tasks' | translate}}</h3>
    <div class="navigation-buttons" *ngIf="paginatedAllTasks.length>0">
      <button mat-icon-button (click)="previousPageAll()"
        [disabled]="currentPageAll === 1">
        <mat-icon svgIcon="mat:navigate_before"></mat-icon>
      </button>
      <button mat-icon-button (click)="nextPageAll()"
        [disabled]="currentPageAll * pageSizeAll >= allTasks.length">
        <mat-icon svgIcon="mat:navigate_next"></mat-icon>
      </button>
    </div>
  </div>
  <a [routerLink]="[]" mat-list-item *ngFor="let item of paginatedAllTasks">
    <h4 matListItemTitle>{{item.name}}</h4>
    <p matListItemLine>{{ item.date | date: 'dd/MM/YYYY'}} - {{item.time}}</p>
  </a>
</mat-nav-list>

<!--
<div class="vex-list-item" matRipple>
  <p>CPU Load (71% / 100%)</p>
  <p class="progress-bar">
    <mat-progress-bar [value]="71" color="primary" mode="determinate"></mat-progress-bar>
  </p>
</div>

<div class="vex-list-item" matRipple>
  <p>RAM Usage (6,175 MB / 16,384 MB)</p>
  <p class="progress-bar">
    <mat-progress-bar [value]="34" color="accent" mode="determinate"></mat-progress-bar>
  </p>
</div>

<div class="vex-list-item" matRipple>
  <p>CPU Temp (43° / 80°)</p>
  <p class="progress-bar">
    <mat-progress-bar [value]="54" color="warn" mode="determinate"></mat-progress-bar>
  </p>
</div>-->
