<vex-page-layout>

  <vex-page-layout-header *ngIf="withHeader"
    class="pb-16 flex flex-col items-start justify-center"
    style="height: 0;">
    <!--<div
         class="w-full flex flex-col sm:flex-row justify-between fullwidth">
      <div>
        <img style="float: left;font-size: 30px;margin: 0 10px 0 0;color: #f53d7c;" [src]="sectionIcon">
        <h1 class="title mt-0 mb-1" style="float: left;font-size: 24px;">{{ title | translate | uppercase}}</h1>
      </div>
    </div>-->
  </vex-page-layout-header>

  <vex-page-layout-content class="-mt-6 fullwidth">

    <div class="card overflow-auto">

      <div
        style="float:left; width: 100%; padding:2%;"
        *ngIf="openFilters">

        <div style="float:left; width: 30%" *ngIf="entity.includes('bookings')">
          <span style="float:left; width: 100%; font-size:16px">{{
            'table.filter.booking' | translate}}</span>
          <mat-checkbox color="primary" [checked]="reservationTypeSingle"
            (change)="reservationTypeSingle = !reservationTypeSingle; filterData()">{{'table.filter.single'
            | translate}}</mat-checkbox>
          <mat-checkbox color="primary" [checked]="reservationTypeMultiple"
            (change)="reservationTypeMultiple = !reservationTypeMultiple; filterData()">{{'table.filter.multiple'
            | translate}}</mat-checkbox>
        </div>

        <div style="float:left; width: 30%" *ngIf="entity.includes('vouchers')">
          <span style="float:left; width: 100%; font-size:16px">{{
            'table.filter.booking' | translate}}</span>
          <mat-checkbox color="primary" [checked]="trashed"
            (change)="trashed = !trashed; filterData()">{{'table.filter.trashed'
            | translate}}</mat-checkbox>
        </div>

        <div style="float:left; width: 30%"
          *ngIf="(entity.includes('bookings') || entity.includes('courses'))">
          <span style="float:left; width: 100%; font-size:16px">{{
            'table.filter.course' | translate}}</span>
          <mat-checkbox color="primary" [checked]="courseColective"
            (change)="courseColective = !courseColective; filterData()"><span
              style="color:#fac710">{{'course_colective' | translate
              }}</span></mat-checkbox>
          <mat-checkbox color="primary" [checked]="coursePrivate"
            (change)="coursePrivate = !coursePrivate;filterData()"><span
              style="color:#2fca45">{{'course_private' | translate
              }}</span></mat-checkbox>
          <mat-checkbox color="primary" [checked]="courseActivity"
            (change)="courseActivity = !courseActivity;filterData()"><span
              style="color:#00BEFFFF">{{'activity' | translate
              }}</span></mat-checkbox>
        </div>

        <div style="float:left; width: 30%" *ngIf="entity.includes('bookings')">
          <span style="float:left; width: 100%; font-size:16px">{{
            'table.filter.booking' | translate}}</span>
          <mat-checkbox color="primary" [checked]="bookingPayed"
            (change)="bookingPayed = !bookingPayed; filterData()">Paye</mat-checkbox>
          <mat-checkbox color="primary" [checked]="bookingNoPayed"
            (change)="bookingNoPayed = !bookingNoPayed;filterData()">Non
            Paye</mat-checkbox>
        </div>

        <div style="float:left; width: 70%"
          *ngIf="entity.includes('courses') || entity.includes('monitors')">
          <span style="float:left; width: 100%; font-size:16px">{{ 'sport' |
            translate}}</span>
          <mat-form-field appearance="outline" style="float:left; width: 100%;"
            class="flex-auto">

            <mat-select [formControl]="sportsControl" multiple
              (selectionChange)="filterData()">

              <mat-select-trigger>
                <div
                  *ngIf="sportsControl.value?.length > 0 && sportsControl.value?.length !== sports.length;">
                  {{ getSelectedSportsNames() }}
                </div>
                <div
                  *ngIf="sportsControl.value?.length === sports.length || !sportsControl.value?.length;">
                  {{ 'ALL' }}
                </div>
              </mat-select-trigger>

              <mat-option *ngFor="let sport of filteredSports | async"
                [value]="sport" (onSelectionChange)="toggleSelection(sport);">
                {{sport.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

      <div
        class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center"
        style="background-color: var(--is-light-theme, #eff2f5) var(--is-dark-theme, #2f3844);">

        <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
          <button (click)="deleteMultiple(selection.selected)"
            color="primary"
            mat-icon-button
            matTooltip="Delete selected"
            type="button">
            <mat-icon svgIcon="mat:delete"></mat-icon>
          </button>

          <button color="primary" mat-icon-button matTooltip="Another action"
            type="button">
            <mat-icon svgIcon="mat:folder"></mat-icon>
          </button>
        </div>

        <div
          class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
          <mat-icon class="icon-sm text-secondary"
            svgIcon="mat:search"></mat-icon>
          <input [formControl]="searchCtrl"
            class="px-4 py-2 border-0 outline-none w-full bg-transparent"
            placeholder="{{'search' | translate}}..."
            type="search">
        </div>

        <!--Bonuses fake -->
        <ng-container *ngIf="entity.includes('vouchers')">
          <div>
            <button class="ml-4 flex-none" mat-button type="button"
              (click)="gift = 0; filterData(true)">
              <span
                [ngStyle]="{'color': !gift ? '#fe3085' : 'var(--color-dark1)'}">{{'vouchers'
                | translate}}</span>
            </button>
          </div>
          <div>
            <button class="ml-4 flex-none" mat-button type="button"
              (click)="gift = 1; filterData()">
              <span
                [ngStyle]="{'color': gift ? '#fe3085' : 'var(--color-dark1)'}">{{'gift_vouchers'
                | translate}}</span>
            </button>
          </div>
          <div>
            <button class="ml-4 flex-none" mat-button type="button"
              (click)="router.navigate(['./discount-codes'])">
              <span
                [ngStyle]="{'color': activeCourse ? '#fe3085' : 'var(--color-dark1)'}">{{'discount_code'
                | translate}}</span>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="entity.includes('discount')">
          <div>
            <button class="ml-4 flex-none" mat-button type="button"
              (click)="navigateWithParam('./vouchers', '0')">
              <span
                [ngStyle]="{'color': activeCourse ? '#fe3085' : 'var(--color-dark1)'}">{{'vouchers'
                | translate}}</span>
            </button>
          </div>
          <div>
            <button class="ml-4 flex-none" mat-button type="button"
              (click)="navigateWithParam('./vouchers', '1')">
              <span
                [ngStyle]="{'color': finishedCourse ? '#fe3085' : 'var(--color-dark1)'}">{{'gift_vouchers'
                | translate}}</span>
            </button>
          </div>
          <div>
            <button class="ml-4 flex-none" mat-button type="button">
              <span
                [ngStyle]="{'color': allCourse ? '#fe3085' : 'var(--color-dark1)'}">{{'discount_code'
                | translate}}</span>
            </button>
          </div>
        </ng-container>

        <!--Bonuses fake close-->
        <ng-container *ngIf="entity.includes('courses')">
          <div>
            <button class="ml-4 flex-none" mat-button type="button"
              (click)="activeCourse = true;inActiveCourse=false;finishedCourse = false; allCourse = false;filterData()">
              <span
                [ngStyle]="{'color': activeCourse ? '#fe3085' : 'var(--color-dark1)'}">{{'active'
                | translate}}</span>
            </button>
          </div>
          <!--<div *ngIf="entity.includes('courses')">
          <button class="ml-4 flex-none" mat-button type="button" (click)="inActiveCourse = true;activeCourse=false;finishedCourse = false; allCourse = false;filterData()">
            <span [ngStyle]="{'color': inActiveCourse ? '#fe3085' : 'var(--color-dark1)'}">{{'inactive' | translate}}</span>
          </button>
        </div>-->
          <div>
            <button class="ml-4 flex-none" mat-button type="button"
              (click)="finishedCourse = true;activeCourse=false;inActiveCourse = false; allCourse = false;filterData()">
              <span
                [style.color]="finishedCourse ? '#fe3085' : 'var(--color-dark1)'">{{'finished'
                | translate}}</span>
            </button>
          </div>
          <div>
            <button class="ml-4 flex-none" mat-button type="button"
              (click)="finishedCourse = false;activeCourse=false;inActiveCourse = true; allCourse = false;filterData()">
              <span
                [ngStyle]="{'color': inActiveCourse ? '#fe3085' : 'var(--color-dark1)'}">{{'current'
                | translate}}</span>
            </button>
          </div>
          <div>
            <button class="ml-4 flex-none" mat-button type="button"
              (click)="allCourse = true;activeCourse=false;inActiveCourse = false; finishedCourse = false; filterData(true)">
              <span
                [ngStyle]="{'color': allCourse ? '#fe3085' : 'var(--color-dark1)'}">{{'all'
                |
                translate}}</span>
            </button>
          </div>
        </ng-container>
        <div
          *ngIf="entity.includes('bookings') && !entity.includes('statistics')">
          <button class="ml-4 flex-none" mat-button type="button"
            (click)="activeBooking = true; finishedBooking=false; allBookings=false;finishedBooking=false;filterData()">
            <span
              [ngStyle]="{'color': activeBooking ? '#fe3085' : 'var(--color-dark1)'}">{{'active'
              | translate}}</span>
          </button>
        </div>

        <div
          *ngIf="entity.includes('bookings') && !entity.includes('statistics')">
          <button class="ml-4 flex-none" mat-button type="button"
            (click)="finishedBooking = true; activeBooking=false; allBookings=false; filterData()">
            <span
              [ngStyle]="{'color': finishedBooking ? '#fe3085' : 'var(--color-dark1)'}">{{'finished'
              | translate}}</span>
          </button>
        </div>
        <div
          *ngIf="entity.includes('bookings') && !entity.includes('statistics')">
          <button class="ml-4 flex-none" mat-button type="button"
            (click)="finishedBooking = false; activeBooking=false; allBookings=false; filterData()">
            <span
              [ngStyle]="{'color': !allBookings && !finishedBooking && !activeBooking ? '#fe3085' : 'var(--color-dark1)'}">{{'current'
              | translate}}</span>
          </button>
        </div>
        <div
          *ngIf="entity.includes('bookings') && !entity.includes('statistics')">
          <button class="ml-4 flex-none" mat-button type="button"
            (click)="allBookings = true; activeBooking=false; finishedBooking=false; filterData()">
            <span
              [ngStyle]="{'color': allBookings && !finishedBooking ? '#fe3085' : 'var(--color-dark1)'}">{{'all'
              | translate}}</span>
          </button>
        </div>

        <div
          *ngIf="entity.includes('monitor') && !entity.includes('statistics')">
          <button class="ml-4 flex-none" mat-button type="button"
            (click)="activeMonitor = true; inactiveMonitor=false; allMonitors=false; filterData()">
            <span
              [ngStyle]="{'color': activeMonitor ? '#fe3085' : 'var(--color-dark1)'}">{{'active'
              | translate}}</span>
          </button>
        </div>
        <div
          *ngIf="entity.includes('monitor') && !entity.includes('statistics')">
          <button class="ml-4 flex-none" mat-button type="button"
            (click)="inactiveMonitor = true; activeMonitor=false; allMonitors=false; filterData()">
            <span
              [ngStyle]="{'color': inactiveMonitor ? '#fe3085' : 'var(--color-dark1)'}">{{'inactive'
              | translate}}</span>
          </button>
        </div>
        <div
          *ngIf="entity.includes('monitor') && !entity.includes('statistics')">
          <button class="ml-4 flex-none" mat-button type="button"
            (click)="allMonitors = true; activeMonitor=false; inactiveMonitor=false; filterData()">
            <span
              [ngStyle]="{'color': allMonitors ? '#fe3085' : 'var(--color-dark1)'}">{{'all'
              |
              translate}}</span>
          </button>
        </div>

        <span class="flex-1"></span>

        <button class="ml-4 flex-none" (click)="exportTableToExcel()"
          *ngIf="entity.includes('statistics')"
          mat-icon-button
          [matTooltip]="translateService.instant('filter_data')"
          type="button">
          <mat-icon svgIcon="mat:file_download"></mat-icon>
        </button>

        <button class="ml-4 flex-none" (click)="openFilters = !openFilters"
          *ngIf="!entity.includes('statistics')  &&
                !entity.includes('users') && !entity.includes('clients')"
          mat-icon-button
          [matTooltip]="translateService.instant('filter_data')"
          type="button">
          <mat-icon svgIcon="mat:youtube_searched_for"></mat-icon>
        </button>

        <button [matMenuTriggerFor]="columnFilterMenu"
          class="ml-4 flex-none"
          mat-icon-button
          [matTooltip]="translateService.instant('filter_columns')"
          type="button">
          <mat-icon svgIcon="mat:filter_list"></mat-icon>
        </button>

        <button (click)="create()" *ngIf="!entity.includes('statistics')"
          class="ml-4 flex-none"
          color="primary"
          mat-mini-fab
          [matTooltip]="translateService.instant('add')"
          type="button">
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
      </div>

      <table (matSortChange)="sortData($event)" @stagger #dataTable
        [dataSource]="dataSource" class="w-full" mat-table matSort
        *ngIf="!loading">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
        <ng-container matColumnDef="checkbox">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              color="primary">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" class="w-4" mat-cell>
            <mat-checkbox (change)="$event ? selection.toggle(row) : null"
              (click)="$event.stopPropagation()"
              [checked]="selection.isSelected(row)"
              color="primary">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'text'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell
              style="text-wrap: balance;">{{ row[column.property] }}</td>
          </ng-container>
        </ng-container>

        <!-- Trads Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'trads'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell
              style="text-wrap: balance;">{{ getTrad(data[i].translations,
              data[i].name) }}</td>
          </ng-container>
        </ng-container>

        <!-- id Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'id'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell
              style="text-wrap: balance;">
              <span *ngIf="data[i].color !== ''" class="dot"
                [ngStyle]="{'background': data[i].color}"></span>
              <span style="float: right;width: 50%;">{{ row[column.property]
                }}</span>
            </td>
          </ng-container>
        </ng-container>

        <!-- QR Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'qr'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell
              style="text-wrap: balance;">
              <mat-icon svgIcon="mat:cloud_download" style="cursor: pointer"
                (click)="exportQR(data[i].id)"></mat-icon>
            </td>
          </ng-container>
        </ng-container>

        <!-- Paiment method Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'payment_method_id'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ getPaymentMethod(row[column.property]) | translate }}
            </td>
          </ng-container>
        </ng-container>

        <!-- Paiment method Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'payment_method'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ getPaymentMethod(row[column.property]) | translate }}
            </td>
          </ng-container>
        </ng-container>

        <!-- Count Columns -->

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'count'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell
              style="text-align: center;">{{ row[column.property].length + 1
              }}</td>
          </ng-container>
        </ng-container>

        <!-- Booking users Columns -->

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'booking_users'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{
              getBookingCourse(row[column.property]) }}</td>
          </ng-container>
        </ng-container>

        <!-- Course type array monitor/client Columns -->

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'course_type_data'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell>{{ data[i].course.name
              }}</td>
          </ng-container>
        </ng-container>

        <!-- Course image Columns -->

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'course_image'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <img
                [src]="getCourseType(data[i]) === 'prive' ? data[i].sport.icon_prive : data[i].sport.icon_collective"
                class="avatar h-8 w-8 align-middle"
                style="border-radius: 5px;min-width: 48px;min-height: 48px;">
              <!--<ng-container *ngIf="data[i].booking_users > 1" >
                <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ 'MULTIPLE'}}</td>
              </ng-container>-->
            </td>
          </ng-container>
        </ng-container>

        <!-- Booking users image Columns -->

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'booking_users_image'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <img *ngIf="row[column.property] != 'multiple'"
                [src]="row[column.property]" class="avatar h-8 w-8 align-middle"
                style="border-radius: 5px;min-width: 48px;min-height: 48px;">
              <!--<ng-container *ngIf="data[i].booking_users > 1" >
                <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ 'MULTIPLE'}}</td>
              </ng-container>-->
            </td>
          </ng-container>
        </ng-container>

        <!-- Booking users image Columns Monitors -->

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'booking_users_image_monitors'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <img [src]="row[column.property]?.sport"
                class="avatar h-8 w-8 align-middle"
                style="border-radius: 5px;min-width: 48px;min-height: 48px;">
              <!--<ng-container *ngIf="data[i].booking_users > 1" >
                <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ 'MULTIPLE'}}</td>
              </ng-container>-->
            </td>
          </ng-container>
        </ng-container>

        <!-- Booking users dates Columns -->

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'booking_dates'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row;let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <!--Jours {{ getMinMaxDates(data[i].booking_users).days}}<br>-->
              <strong>{{ getMinMaxDates(data[i].booking_users).minDate | date:
                'dd/MM/YYYY'}}<br>{{
                getMinMaxDates(data[i].booking_users).maxDate| date:
                'dd/MM/YYYY' }}</strong><br>
              {{getMinMaxHours(data[i].booking_users).minHour }}h -
              {{getMinMaxHours(data[i].booking_users).maxHour}}h
            </td>
          </ng-container>
        </ng-container>

        <!-- Sports Columns -->

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'sports'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <span *ngIf="row[column.property].length === 1">{{
                getSportName(row[column.property][0]?.sport_id)?.name }}</span>
              <span *ngIf="row[column.property].length === 2">{{
                getSportName(row[column.property][0]?.sport_id)?.name }} -
                {{getSportName(row[column.property][1]?.sport_id)?.name}}</span>
              <span *ngIf="row[column.property].length > 2">{{
                getSportName(row[column.property][0]?.sport_id)?.name }} -
                {{getSportName(row[column.property][1]?.sport_id)?.name}}
                (+{{row[column.property].length - 2}})</span>
            </td>

          </ng-container>
        </ng-container>

        <!-- Sports Monitor Columns -->

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'sports_monitor'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <span *ngIf="row[column.property].length === 1">{{
                row[column.property][0].name }}</span>
              <span *ngIf="row[column.property].length === 2">{{
                row[column.property][0].name }} -
                {{row[column.property][1].name}}</span>
              <span *ngIf="row[column.property].length > 2">{{
                row[column.property][0].name }} -
                {{row[column.property][1].name}} (+{{row[column.property].length
                - 2}})</span>
            </td>
          </ng-container>
        </ng-container>

        <!-- Birth Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'birth'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{
              calculateAge(row[column.property]) }}</td>
          </ng-container>
        </ng-container>

        <!-- Bookings Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'bookings'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <strong>
                <span
                  style="color:#2D9BF0">{{data[i].total_reservations}}
                </span><span *ngIf="data[i].total_available_places">/
                  {{data[i].total_available_places.toFixed(0)}}</span>
              </strong>
            </td>
          </ng-container>
        </ng-container>

        <!-- Price Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'price'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <strong *ngIf="!data[i].is_flexible">{{
                row[column.property] }} {{
                data[i].currency }}</strong>
              <strong
                *ngIf="data[i].is_flexible && data[i].course_type === 1">{{'from'
                | translate }} {{ row[column.property] }}
                {{data[i].currency}}</strong>
              <strong
                *ngIf="data[i].is_flexible && (data[i].course_type === 2 || data[i].course_type === 3) && data[i].price_range !== null">{{'from'
                | translate }} {{
                encontrarPrimeraClaveConValor(encontrarPrimeraCombinacionConValores(data[i].price_range,data[i]))
                }} {{data[i].currency}}</strong>
              <strong
                *ngIf="data[i].is_flexible && data[i].course_type === 2 && data[i].price_range === null">
                {{"NO PRICE RANGE SET" | translate}}</strong>
            </td>
          </ng-container>
        </ng-container>

        <!-- Price Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'payment_status'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell
              [style.color]=" row[column.property] ? '#CEE741' : 'red'">
              {{ (row[column.property] ? 'yes' : 'no')| translate | uppercase }}
            </td>
          </ng-container>
        </ng-container>

        <!-- Client status Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'clients_schools'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell
              [style.color]="checkClientStatus(row[column.property]) ? '#CEE741' : 'red'">
              {{ (checkClientStatus(row[column.property]) ? 'active' :
              'inactive')| translate | uppercase }}
            </td>
          </ng-container>
        </ng-container>

        <!-- payment_status_data Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'payment_status_data'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row;let i = index"
              [ngClass]="column.cssClasses" mat-cell
              [style.color]=" row[column.property] ? '#CEE741' : 'red'">
              {{ (data[i].booking[column.property] ? 'payed' : 'cancelled') |
              translate | uppercase }}
            </td>
          </ng-container>
        </ng-container>

        <!-- Cancelation Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'cancelation_status'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell
              [style.color]=" row[column.property] === 1 ? '#CEE741' : '#fac710'">
              {{ (row[column.property] === 1 ? 'active' : row[column.property]
              === 2 ? 'cancelled' : 'partial_cancelled') | translate | uppercase
              }}
            </td>
          </ng-container>
        </ng-container>

        <!-- Light Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'light'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div *ngIf="row[column.property]"
                style="background-color: #CEE741;width: 25px;border-radius: 100%;height: 25px;"></div>
              <div *ngIf="!row[column.property]"
                style="background-color: #E6E6E6;width: 25px;border-radius: 100%;height: 25px;"></div>
            </td>
          </ng-container>
        </ng-container>

        <!-- Light DATA Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'light_data'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row;let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <div *ngIf="data[i].booking[column.property]"
                style="background-color: #CEE741;width: 25px;border-radius: 100%;height: 25px;margin: 0 0 0 20%;"></div>
              <div *ngIf="!data[i].booking[column.property]"
                style="background-color: #E6E6E6;width: 25px;border-radius: 100%;height: 25px;margin: 0 0 0 20%;"></div>
            </td>
          </ng-container>
        </ng-container>

        <!-- Light school Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'light_school'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div *ngIf="row[column.property] === schoolId"
                style="background-color: #CEE741;width: 25px;border-radius: 100%;height: 25px;margin: 0 0 0 20%;"></div>
              <div *ngIf="row[column.property] !== schoolId"
                style="background-color: #E6E6E6;width: 25px;border-radius: 100%;height: 25px;margin: 0 0 0 20%;"></div>
            </td>
          </ng-container>
        </ng-container>

        <!-- light_monitors_schools school Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'light_monitors_schools'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div
                [ngStyle]="{'background': getActiveSchool(row[column.property]) ? '#CEE741' : '#E6E6E6'}"
                style="width: 25px;border-radius: 100%;height: 25px;margin: 0 0 0 20%;"></div>
            </td>
          </ng-container>
        </ng-container>

        <!-- Status Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'status'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div *ngIf="row[column.property]"
                style="background-color: #CEE741;width: 25px;border-radius: 100%;height: 25px;margin: 0 0 0 5%;"></div>
              <div *ngIf="!row[column.property]"
                style="background-color: #E6E6E6;width: 25px;border-radius: 100%;height: 25px;margin: 0 0 0 5%;"></div>
            </td>
          </ng-container>
        </ng-container>

        <!-- Level Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'level'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header>{{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <div *ngIf="data[i].client_sports.length > 0" class="m-0 body-1"
                [style.background]="data[i].client_sports[0]?.degree?.color"
                style="padding: 5%;;color: #fff;border-radius: 5px;width: 100%;text-align: center; font-size:10px;overflow: inherit;">
                {{data[i].client_sports[0]?.degree?.annotation}}
                {{data[i].client_sports[0].degree?.name}}
              </div>
            </td>
          </ng-container>
        </ng-container>

        <!-- Level Monitor Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'monitor_sports_degrees'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header>MAX. {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <div class="m-0 body-1"
                [style.background]="findHighestDegreeIdElement(row[column.property])?.color"
                style="padding: 5%;;color: #fff;border-radius: 5px;width: 100%;text-align: center; font-size:10px;overflow: inherit;">
                {{findHighestDegreeIdElement(row[column.property])?.annotation}}
                {{findHighestDegreeIdElement(row[column.property])?.name}}
              </div>
            </td>
          </ng-container>
        </ng-container>

        <!-- Client Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'client'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <img
                [src]="row[column.property]?.image !== null && row[column.property]?.image !== '' ? row[column.property]?.image : imageAvatar"
                style="float: left;width: 40px;height: 40px;border-radius: 100%;margin: 0 5px 0 0;object-fit: cover;">
              <span
                style="float: left; width: 50%;font-size:10px">{{getLanguage(row[column.property]?.language1_id)}}
                · {{getCountry(row[column.property]?.country)}} ·
                {{calculateAge(row[column.property]?.birth_date)}} {{'years' |
                translate }}</span>
              <span style="float: left; width: 50%;">{{
                row[column.property]?.first_name }} {{
                row[column.property]?.last_name }}</span>
            </td>
          </ng-container>
        </ng-container>

        <!-- Client Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'client_2'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <img
                [src]="data[i]?.image !== null && data[i]?.image !== '' ? data[i]?.image : imageAvatar"
                style="float: left;width: 40px;height: 40px;border-radius: 100%;margin: 0 5px 0 0;object-fit: cover;">
              <span
                style="float: left; width: 50%;font-size:10px">{{getLanguage(data[i]?.language1_id)}}
                · {{getCountry(data[i]?.country)}} ·
                {{calculateAge(data[i]?.birth_date)}} {{'years' | translate
                }}</span>
              <span style="float: left; width: 50%;">{{ data[i]?.first_name }}
                {{ data[i]?.last_name }}</span>
            </td>
          </ng-container>
        </ng-container>

        <!-- Monitor Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'monitor'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell style="width: 70px;">
              <img
                [src]="data[i]?.image !== null && data[i]?.image !== '' ? data[i]?.image : imageAvatar"
                style="float: left;width: 40px;height: 40px;border-radius: 100%;margin: 0 5px 0 0;object-fit: cover;">
              <span
                style="float: left; width: 50%;font-size:10px">{{getLanguage(data[i]?.language1_id)}}
                · {{getCountry(data[i]?.country)}} ·
                {{calculateAge(data[i]?.birth_date)}} {{'years' | translate
                }}</span>
              <span style="float: left; width: 50%;">{{ data[i]?.first_name }}
                {{ data[i]?.last_name }}</span>
            </td>
          </ng-container>
        </ng-container>

        <!-- Dates Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'dates'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell
              [innerHTML]="transformDates(row[column.property])"></td>
          </ng-container>
        </ng-container>

        <!-- Dates Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'datesCourse'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row, let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <strong><span
                  style="text-align:center">{{countActives(row[column.property])}}
                  {{'days' | translate}}</span></strong><br>
              <span
                style="text-align:center">{{findFirstActive(row[column.property]).min
                | date: 'dd/MM/YYYY'}} -
                {{findFirstActive(row[column.property]).max | date:
                'dd/MM/YYYY'}}</span>
            </td>
          </ng-container>
        </ng-container>

        <!-- Register Date Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'register_date'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell
              [innerHTML]="transformRegisterDates(row[column.property])"></td>
          </ng-container>
        </ng-container>

        <!-- Register Date Columns
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] | date: 'dd/MM/YYYY' }}</td>
          </ng-container>
        </ng-container>-->

        <!-- Register Date Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'date'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{
              row[column.property] | date: 'dd/MM/YYYY' }}
              <br> {{ row[column.property] | date: 'HH:mm' }}</td>
          </ng-container>
        </ng-container>

        <!-- Register Date Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'duration'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row;let i = index"
              [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] ?
              row[column.property] : getShortestDuration(data[i]?.course_dates)
              }}</td>
          </ng-container>
        </ng-container>

        <!-- Sport Date Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'sport'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{
              row[column.property] ? row[column.property].name : 'NDF' }}</td>
          </ng-container>
        </ng-container>

        <!-- Sport Date Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'flexible'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{
              row[column.property] ? 'FX' : 'FI' }}</td>
          </ng-container>
        </ng-container>

        <!--        &lt;!&ndash; Image Columns &ndash;&gt;
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'image'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <img *ngIf="row[column.property] === 1" [src]="'https://api.boukii.com/storage/icons/collectif_ski2x.png'" class="avatar h-8 w-8 align-middle"  style="border-radius: 5px;min-width: 48px;min-height: 48px; width: 100%;">
              <img *ngIf="row[column.property] !== 1" [src]="'https://api.boukii.com/storage/icons/prive_ski2x.png'" class="avatar h-8 w-8 align-middle"  style="border-radius: 5px;min-width: 48px;min-height: 48px; width: 100%;">
            </td>
          </ng-container>
        </ng-container>-->

        <!-- Coronita Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'coronita'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses" mat-cell>
              <img *ngIf="data[i].utilizers.length > 0"
                [src]="'../../../assets/img/icons/cuentagrupo.svg'"
                class="avatar h-8 w-8 align-middle"
                style="height: 25px;margin: 0 0 0 35%;width: 30px;">
              <img *ngIf="data[i].utilizers.length === 0"
                [src]="'../../../assets/img/icons/cuentaunica.svg'"
                class="avatar h-8 w-8 align-middle"
                style="height: 25px;margin: 0 0 0 35%;width: 30px;">
            </td>
          </ng-container>
        </ng-container>

        <!-- Active Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'active'"
            [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell
              mat-sort-header> {{ column.label | translate }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{
              row[column.property] ? 'SI' : 'NO'}}</td>
          </ng-container>
        </ng-container>

        <!-- Contact Column -->
        <ng-container matColumnDef="contact">
          <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" mat-cell>
            <div class="flex">
              <a (click)="$event.stopPropagation()"
                class="w-8 h-8 p-0 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary/10"
                mat-icon-button>
                <mat-icon class="icon-sm">phone</mat-icon>
              </a>

              <a (click)="$event.stopPropagation()"
                class="w-8 h-8 p-0 leading-none flex items-center justify-center ml-1 hover:bg-hover text-teal bg-teal/10"
                mat-icon-button>
                <mat-icon class="icon-sm">mail</mat-icon>
              </a>

              <a (click)="$event.stopPropagation()"
                class="w-8 h-8 p-0 leading-none flex items-center justify-center ml-1 hover:bg-hover text-green bg-green/10"
                mat-icon-button>
                <mat-icon class="icon-sm" svgIcon="mat:map"></mat-icon>
              </a>
            </div>
          </td>
        </ng-container>

        <!-- Label Column -->
        <ng-container matColumnDef="labels">
          <th *matHeaderCellDef class="uppercase" mat-header-cell
            mat-sort-header>Labels</th>
          <td *matCellDef="let row" mat-cell>
            <div (click)="$event.stopPropagation()"
              class="flex items-center gap-1">
              <div *ngFor="let label of row.labels"
                [ngClass]="[label.textClass, label.bgClass]"
                class="rounded px-2 py-1 font-medium text-xs flex-none">
                {{ label.text }}
              </div>
              <div
                class="text-secondary bg-base text-hint cursor-pointer hover:bg-hover flex-none flex items-center justify-center">
                <mat-icon class="icon-sm" svgIcon="mat:add"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
            <button (click)="$event.stopPropagation()"
              [matMenuTriggerData]="{ item: row }"
              [matMenuTriggerFor]="actionsMenu"
              mat-icon-button
              type="button">
              <mat-icon svgIcon="mat:more_horiz"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr (click)="showDetailFn(row)"
          [ngStyle]="{'background': entity === '/bookings' && row.price_total - row.paid_total < 0 && row.paid ? '#ffdaa9' : ''}"
          *matRowDef="let row; columns: visibleColumns;"
          @fadeInUp
          class="hover:bg-hover trans-ease-out cursor-pointer"
          mat-row></tr>
      </table>

      <mat-paginator class="sticky left-0"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="10"
        [length]="totalRecords"
        (page)="onPageChange($event)">
      </mat-paginator>
    </div>

  </vex-page-layout-content>

</vex-page-layout>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="mat-menu-item block">
    <mat-checkbox (click)="$event.stopPropagation()"
      [(ngModel)]="column.visible" color="primary">
      {{ column.label | translate }}
    </mat-checkbox>
  </button>
</mat-menu>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-item="item" matMenuContent>
    <button (click)="update(item)" mat-menu-item>
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>{{'update' | translate}}</span>
    </button>
    <button (click)="delete(item)" mat-menu-item *ngIf="canDelete">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>{{'delete_title' | translate}}</span>
    </button>
    <button (click)="deactivate(item)" mat-menu-item
      *ngIf="canDeactivate && entity.includes('monitors')">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span *ngIf="item.active">{{'deactivate' | translate }}</span>
      <span *ngIf="!item.active">{{'activate' | translate }}</span>
    </button>
    <button (click)="deactivate(item)" mat-menu-item
      *ngIf="canDeactivate && !entity.includes('monitors')">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>{{'deactivate' | translate }}</span>
    </button>
    <button (click)="delete(item)" mat-menu-item
      *ngIf="canDeactivate && entity.includes('courses') && item.booking_users.length == 0">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>{{'delete_title' | translate }}</span>
    </button>
    <button (click)="copy(item)" mat-menu-item *ngIf="canDuplicate">
      <mat-icon svgIcon="mat:file_copy"></mat-icon>
      <span>{{'duplicate' | translate }}</span>
    </button>
    <button (click)="delete(item)" mat-menu-item
      *ngIf="entity.includes('vouchers') && !trashed">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>{{'delete_title' | translate }}</span>
    </button>
    <button (click)="restore(item)" mat-menu-item
      *ngIf="entity.includes('vouchers') && trashed">
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>{{'restore_title' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
