<form >
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{'add_task' | translate }}</h2>

    <!--<button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>-->

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">

    <div [formGroup]="form">
      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 5% 0 0 0">
        <mat-form-field appearance="outline" class="flex-auto">
          <mat-label>{{'name' | translate }}</mat-label>
          <input formControlName="name" matInput required type="text">

        </mat-form-field>
      </div>

      <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 5% 0 0 0">
        <mat-form-field appearance="outline" class="flex-auto">
          <input id="date" matInput [matDatepicker]="picker" formControlName="date" placeholder="{{'date' | translate}}" [(ngModel)]="selectedDate" [min]="today">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker [startAt]="today"></mat-datepicker>
        </mat-form-field>

        <div class="flex-auto">
          <mat-form-field appearance="outline" style="width: 100%;">
            <input id="hour" type="text" placeholder="Hora" formControlName="time" matInput [matAutocomplete]="auto" [(ngModel)]="selectedHour">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let hour of hours" [value]="hour">
                {{hour}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
   </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button color="primary" mat-flat-button [mat-dialog-close]="false">{{'no' | translate}}</button>
    <button color="primary" mat-flat-button [disabled]="form.invalid" (click)="save()">{{'confirm' | translate}}</button>
  </mat-dialog-actions>
</form>
