<div (mouseenter)="showButton = true"
  (mouseleave)="showButton = false"
  class="card p-6 relative flex flex-col items-center"
  style="border-radius: 50px;box-shadow: none;"
  [ngStyle]="{'background-color': bg}">
  <div [ngClass]="iconClass"
    class="rounded-full w-12 h-12 flex items-center justify-center">
    <mat-icon *ngIf="!noIcon" [svgIcon]="icon"></mat-icon>
    <img [src]="icon" *ngIf="noIcon">
  </div>

  <h3 class="subheading-2 font-medium text-secondary m-0">{{ label | translate
    }}</h3>
  <h4 class="subheading-2 font-medium text-secondary m-0">{{ label_secondary |
    translate }}</h4>
  <br *ngIf="label_secondary === ''">
  <h2 class="display-2 font-bold mt-4 mb-2" [ngStyle]="{'color': valueColor}">{{
    value }}</h2>

  <h4 [class.text-green]="change > 0"
    [class.text-red]="change <= 0"
    class="subheading-1 font-medium mt-4 mb-0 flex items-center">
    <span *ngIf="withPercent">{{ change }}%</span>
    <span *ngIf="onlyValue">{{ change }}</span>
  </h4>

  <button *ngIf="helpText"
    [matTooltip]="helpText"
    class="absolute top-0 left-0 mt-2 ml-2 text-hint"
    mat-icon-button
    matTooltipPosition="after"
    type="button">
    <mat-icon class="icon-sm" svgIcon="mat:help"></mat-icon>
  </button>

  <button (click)="openSheet()"
    *ngIf="showButton && canShare"
    @scaleInOut
    class="absolute -top-3 -right-3 bg-foreground shadow-xl"
    color="primary"
    mat-icon-button
    type="button">
    <mat-icon svgIcon="mat:share"></mat-icon>
  </button>
</div>
