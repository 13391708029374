<form *ngIf="!loading">
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{'new_client' | translate }}</h2>

    <!--<button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>-->

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

    <div @fadeInUp class="card flex-auto">
      <div class="px-6 py-4 border-b flex items-center">
        <div style="float: left;width: 50%;">
          <h2 class="title m-0">{{'new_client' | translate }}</h2>
        </div>
        <div style="float: left;width: 50%;">
          <div style="float:right">
            {{'active' | translate}} <mat-slide-toggle [(ngModel)]="defaultsUser.active" [ngModelOptions]="{standalone: true}"></mat-slide-toggle>
          </div>
        </div>
      </div>
      <mat-vertical-stepper #stepper="matHorizontalStepper" [linear]="true">

        <ng-template matStepperIcon="edit">
          <mat-icon svgIcon="mat:done_all"></mat-icon>
        </ng-template>

        <ng-template matStepperIcon="done">
          <mat-icon svgIcon="mat:done_all"></mat-icon>
        </ng-template>

        <mat-step [stepControl]="formInfoAccount">
          <form [formGroup]="formInfoAccount">
            <ng-template matStepLabel>{{'monitors.creation.info' | translate }}</ng-template>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <div class="image-container">
                <img [src]="defaults.image !== null && defaults.image !== '' ? defaults.image : '../../../../assets/img/no-image.jpg'" alt="Image Preview" style="width: 200px;height: 200px;border-radius: 100%;object-fit: fill;">
              </div>

              <div class="input-container">

                <input type="file" formControlName="image" placeholder="Select an image" (change)="onFileChanged($event)" id="file"/>
                <label for="file" class="btn-1"><mat-icon svgIcon="mat:cloud_upload"></mat-icon></label>
                <p style="font-size: 12px">{{'image_max' | translate}}</p>
                <p style="font-size: 12px">{{'image_format' | translate}}</p>
                <p style="font-size: 12px">{{'image_recomendation' | translate}}</p>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 3% 0 0 0;">
              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'name_client' | translate }}</mat-label>
                <input formControlName="name" matInput required type="text" [(ngModel)]="defaults.first_name">
                <mat-icon class="mr-2" matPrefix svgIcon="logo:task"></mat-icon>
                <mat-error *ngIf="formInfoAccount.controls?.name?.errors?.['required']">
                  {{'clients.creation.errors.name' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'surname' | translate }}</mat-label>
                <input formControlName="surname" matInput required type="text" [(ngModel)]="defaults.last_name">
                <mat-icon class="mr-2" matPrefix svgIcon="logo:task"></mat-icon>
                <mat-error *ngIf="formInfoAccount.controls?.surname?.errors?.['required']">
                  {{'course_detail' |translate}}
                </mat-error>
              </mat-form-field>
             </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>Email</mat-label>
                <input formControlName="email" matInput required type="mail" [(ngModel)]="defaults.email">
                <mat-icon class="mr-2" matPrefix svgIcon="logo:mail"></mat-icon>
                <mat-error *ngIf="formInfoAccount.controls?.email?.errors?.['required']">
                  {{'clients.creation.errors.email' | translate }}
                </mat-error>
                <mat-error *ngIf="formInfoAccount.controls?.email?.errors?.['email']">
                  {{'clients.creation.errors.email_format' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'username' | translate }}</mat-label>
                <input formControlName="username" matInput type="text" [(ngModel)]="defaultsUser.username">
                <mat-icon class="mr-2" matPrefix svgIcon="logo:task"></mat-icon>
              </mat-form-field>
            </div>
            <div class="actions flex items-center justify-end gap-2">
              <button mat-button type="button" [mat-dialog-close]="false">{{'cancel' | translate }}</button>

              <button (click)="stepper.reset()" [disabled]="formInfoAccount.pristine" color="primary" mat-button type="button">Reset</button>
              <button [disabled]="formInfoAccount.invalid" color="primary" mat-raised-button matStepperNext>
                {{'next' | translate }}
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="formPersonalInfo">
          <form [formGroup]="formPersonalInfo">
            <ng-template matStepLabel>{{'personal_data' | translate }}</ng-template>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 3% 0 0 0;">

              <mat-form-field appearance="outline" class="flex-auto">
                <input matInput [matDatepicker]="pickerFrom" placeholder="{{'birth_date' | translate }}" formControlName="fromDate" [max]="minDate" [(ngModel)]="defaults.birth_date">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom [startAt]="minDate"></mat-datepicker>
                <mat-error *ngIf="formPersonalInfo.controls?.fromDate?.errors?.['required']">
                 {{'clients.creation.errors.monitor_date' | translate }}
                </mat-error>
              </mat-form-field>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'phone' | translate }}</mat-label>

                <input formControlName="phone" matInput type="tel"  [(ngModel)]="defaults.telephone">
                <mat-icon class="mr-2" matPrefix svgIcon="logo:phone"></mat-icon>

              </mat-form-field>

              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'mobile' | translate }}</mat-label>
                <input formControlName="mobile" matInput required type="tel" [(ngModel)]="defaults.phone">
                <mat-icon class="mr-2" matPrefix svgIcon="logo:phone"></mat-icon>

                <mat-error *ngIf="formPersonalInfo.controls?.mobile?.errors?.['required']">
                  {{'clients.creation.errors.mobile' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'address' | translate }}</mat-label>

                <input formControlName="address" matInput type="text" [(ngModel)]="defaults.address">
                <mat-icon class="mr-2" matPrefix svgIcon="logo:task"></mat-icon>
              </mat-form-field>

              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'cp' | translate }}</mat-label>
                <input formControlName="postalCode" matInput type="text" [(ngModel)]="defaults.cp">
                <mat-icon class="mr-2" matPrefix svgIcon="logo:task"></mat-icon>
              </mat-form-field>
            </div>



            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
                <mat-label>{{'country' | translate }}</mat-label>
                <input type="text" placeholder="Selecciona una opción" matInput [formControl]="myControlCountries" [matAutocomplete]="autoCountry" formControlName="country">
                <mat-autocomplete #autoCountry="matAutocomplete" [displayWith]="displayFnCountry">
                  <mat-option *ngFor="let country of filteredCountries | async" [value]="country" (onSelectionChange)="defaults.country = country.id">
                    {{country.name}}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon class="mr-2" matPrefix svgIcon="logo:task"></mat-icon>
              </mat-form-field>

              <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
                <mat-label>{{'province' | translate }}</mat-label>
                <input type="text" placeholder="Selecciona una opción" matInput [formControl]="myControlProvinces" [matAutocomplete]="autoProvince" formControlName="province">
                <mat-autocomplete #autoProvince="matAutocomplete" [displayWith]="displayFnProvince">
                  <mat-option *ngFor="let province of filteredProvinces | async" [value]="province" (onSelectionChange)="defaults.province = province.id">
                    {{province.name}}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon class="mr-2" matPrefix svgIcon="logo:task"></mat-icon>
              </mat-form-field>

              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'langs' | translate }}</mat-label>
                <mat-select [formControl]="languagesControl" multiple>
                  <mat-select-trigger>
                    <div *ngIf="selectedLanguages.length > 0; else placeholderLangs">
                      {{ getSelectedLanguageNames() }}
                    </div>
                    <ng-template #placeholderLangs>{{'choose_langs' | translate }}</ng-template>
                  </mat-select-trigger>
                  <mat-option *ngFor="let language of filteredLanguages | async" [value]="language" (onSelectionChange)="toggleSelectionLanguages(language)">
                    {{language.name}}
                  </mat-option>
                </mat-select>
                <mat-icon class="mr-2" matPrefix svgIcon="logo:message-text"></mat-icon>
              </mat-form-field>
            </div>

            <div class="actions flex items-center justify-end gap-2">
              <button mat-button type="button" [mat-dialog-close]="false">{{'cancel' | translate }}</button>

              <button (click)="stepper.reset()" [disabled]="formPersonalInfo.pristine" color="primary" mat-button type="button">Reset</button>
              <button [disabled]="formPersonalInfo.invalid" color="primary" mat-raised-button (click)="goToStep3(stepper)">
                {{'next' | translate}}
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="formSportInfo">
          <form [formGroup]="formSportInfo">
            <ng-template matStepLabel>{{'sports' |translate}}</ng-template>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 2% 0 0 0;">
              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'sports' |translate}}</mat-label>
                <mat-select [formControl]="sportsControl" multiple>

                  <mat-select-trigger>
                    <div *ngIf="sportsControl.value?.length > 0; else placeholder">
                      {{ getSelectedSportsNames() }}
                    </div>
                    <ng-template #placeholder>{{'choose_sports' | translate }}</ng-template>
                  </mat-select-trigger>

                  <mat-option *ngFor="let sport of filteredSports | async" [value]="sport" (onSelectionChange)="toggleSelection(sport)">
                    {{sport.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">

              <!-- Añadir niños con modal -->
               <mat-table #sportsTable [dataSource]="sportsData" *ngIf="this.selectedSports.length > 0">

                 <!-- Otros contenedores según tu descripción -->

                 <ng-container matColumnDef="name">
                   <mat-header-cell *matHeaderCellDef> {{'name' | translate }}</mat-header-cell>
                   <mat-cell *matCellDef="let element">
                     <mat-form-field appearance="outline" class="flex-auto" style="margin: 2% 0 0 0;">
                       <mat-label>{{'name' | translate }}</mat-label>
                       <input matInput [value]="element.name" type="text" [readonly]="true">
                     </mat-form-field>
                   </mat-cell>
                 </ng-container>

                 <ng-container matColumnDef="date">
                   <mat-header-cell *matHeaderCellDef>{{'level' | translate }}</mat-header-cell>
                   <mat-cell *matCellDef="let element">
                     <mat-form-field appearance="outline" class="flex-auto" style="margin: 2% 0 0 0;">
                      <mat-label>{{'level' | translate }}</mat-label>
                      <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="levelForm" [matAutocomplete]="autoLevel" [(ngModel)]="element.level"/>
                      <mat-autocomplete #autoLevel="matAutocomplete" [displayWith]="displayFnLevel">
                        <mat-option *ngFor="let level of element.degrees" [value]="level">
                          {{level.annotation}} - {{level.name}}
                        </mat-option>
                      </mat-autocomplete>
                     </mat-form-field>
                   </mat-cell>
                 </ng-container>

                 <!-- Ícono para eliminar
                 <ng-container matColumnDef="delete">
                   <mat-header-cell *matHeaderCellDef> Eliminar </mat-header-cell>
                   <mat-cell *matCellDef="let element">
                     <mat-icon svgIcon="mat:delete" (click)="removeChild(element)"></mat-icon>
                   </mat-cell>
                 </ng-container> -->

                 <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                 <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
               </mat-table>
             </div>

             <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'observation_general' | translate}}</mat-label>
                <textarea formControlName="summary" matInput [(ngModel)]="defaultsObservations.general"></textarea>
              </mat-form-field>
            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'observation_school' |translate}}</mat-label>
                <textarea formControlName="notes" matInput [(ngModel)]="defaultsObservations.notes"></textarea>
              </mat-form-field>
            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <mat-form-field appearance="outline" class="flex-auto">
                <mat-label>{{'historical' | translate }}</mat-label>
                <textarea formControlName="hitorical" matInput [(ngModel)]="defaultsObservations.historical"></textarea>
              </mat-form-field>
            </div>

            <div class="actions flex items-center justify-end gap-2">
              <button mat-button type="button" [mat-dialog-close]="false">{{'cancel' | translate }}</button>

              <button (click)="stepper.reset()" [disabled]="formPersonalInfo.pristine" color="primary" mat-button type="button">Reset</button>
              <button [disabled]="formPersonalInfo.invalid" color="primary" mat-raised-button matStepperNext (click)="save()">
                {{'save' | translate}}
              </button>
            </div>
          </form>

        </mat-step>
      </mat-vertical-stepper>
    </div>
</form>
