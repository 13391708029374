<div class="modal">
    <div class="modal-content resum card"
        [style.width]="width+'px'"
        [style.left]="'calc(50vw - '+ width/2 + 'px)'"
        style="top: 10%;">
        <div class="modal-header"
            style="display: flex;justify-content: space-between;border-bottom: 1px #DEE6EA solid;">
            <h5 class="modal-title">{{title | translate}} </h5>
            <span class="close" (click)="Close.emit()">&times;</span>
        </div>
        <div class="modal-body">
            <ng-content select="[body]"></ng-content>
        </div>
        <div class="modal-footer">
            <ng-content select="[footer]"></ng-content>
        </div>
    </div>
    <div class="modal-background" (click)="Close.emit()"> </div>
</div>
