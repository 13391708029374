<mat-dialog-content>

  <div class="flex-auto">
    <label for="date">{{'date' | translate}}</label>

    <mat-form-field appearance="outline">
      <input id="date" matInput [matDatepicker]="picker"
        placeholder="{{'date' | translate}}"
        [(ngModel)]="selectedDate" [matDatepickerFilter]="myHolidayFilter"
        [min]="data.minDate" [max]="data.maxDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [startAt]="today"></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="flex-auto">
    <label for="duration">{{'duration' | translate}}</label>

    <mat-form-field appearance="outline" style="width: 100%">
      <input id="duration" type="text" placeholder="{{'duration' | translate}}"
        matInput [matAutocomplete]="autoTimes" [(ngModel)]="selectedDuration">
      <mat-autocomplete #autoTimes="matAutocomplete">
        <mat-option *ngFor="let duration of durations" [value]="duration">
          {{duration}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="flex-auto">
    <label for="hour">{{'hour' | translate}}</label>
    <mat-form-field appearance="outline" style="width: 100%;">
      <input id="hour" type="text" placeholder="{{'hour' | translate}}" matInput
        [matAutocomplete]="auto" [(ngModel)]="selectedHour">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let hour of hours" [value]="hour">
          {{hour}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{'cancel' | translate }}</button>
  <button mat-button
    [mat-dialog-close]="{date: selectedDate, duration: selectedDuration, hour: selectedHour}"
    [disabled]="!selectedDuration || !selectedDate || !selectedHour">{{'confirm'
    | translate}}</button>
</mat-dialog-actions>
