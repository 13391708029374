<div class="cardp" *ngIf="courseFormGroup">
    <div class="cardgroup" *ngIf="mode==='update' && step===3">
        <div
            style="width: 100%; height: 100%;  background: white; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 20px; display: inline-flex">
            <div
                style="align-self: stretch; color: #222222; font-size: 14px; font-family: DM Sans; font-weight: 600; line-height: 18px; word-wrap: break-word">Leyenda</div>
            <div
                style="align-self: stretch; height: 276px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 20px; display: flex">
                <div
                    style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 21px; display: flex">
                    <div
                        style="height: 24px; padding-top: 10px; padding-bottom: 10px; justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                        <div
                            style="width: 24px; height: 24px; position: relative">
                            <img src="assets/icons/icon/monitor.png" alt>
                        </div>
                        <div
                            style="width: 91px; height: 24px; color: var(--color-dark1); font-size: 12px; font-family: DM Sans; font-weight: 600; line-height: 16px; word-wrap: break-word;    align-content: center;">MONITOR</div>
                    </div>
                    <div
                        style="align-self: stretch; height: 70px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: flex">
                        <div
                            style="justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                            <div
                                style="width: 10px; height: 10px; background: #31C044; border-radius: 9999px"></div>
                            <div
                                style="color: #222222; font-size: 14px; font-family: DM Sans; font-weight: 400; line-height: 18px; word-wrap: break-word">Asignado</div>
                        </div>
                        <div
                            style="align-self: stretch; justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                            <div
                                style="width: 10px; height: 10px; background: #FFCF25; border-radius: 9999px"></div>
                            <div
                                style="color: #222222; font-size: 14px; font-family: DM Sans; font-weight: 400; line-height: 18px; word-wrap: break-word">Modificado</div>
                        </div>
                        <div
                            style="justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                            <div
                                style="width: 10px; height: 10px; background: #C7D0D3; border-radius: 9999px"></div>
                            <div
                                style="color: #222222; font-size: 14px; font-family: DM Sans; font-weight: 400; line-height: 18px; word-wrap: break-word">Sin
                                monitor</div>
                        </div>
                    </div>
                </div>
                <div
                    style="height: 141px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 21px; display: flex">
                    <div
                        style="height: 24px; padding-top: 10px; padding-bottom: 10px; justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                        <div
                            style="width: 24px; height: 24px; position: relative">
                            <img src="assets/icons/icon/alumno.png" alt>
                        </div>
                        <div
                            style="width: 91px; height: 24px; color: var(--color-dark1); font-size: 12px; font-family: DM Sans; font-weight: 600; line-height: 16px; word-wrap: break-word;    align-content: center;">ALUMNOS</div>
                    </div>
                    <div
                        style="align-self: stretch; height: 96px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 8px; display: flex">
                        <div
                            style="justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                            <div
                                style="width: 10px; height: 10px; background: #31C044; border-radius: 9999px"></div>
                            <div
                                style="color: #222222; font-size: 14px; font-family: DM Sans; font-weight: 400; line-height: 18px; word-wrap: break-word">Presente
                                el día del curso </div>
                        </div>
                        <div
                            style="align-self: stretch; justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                            <div
                                style="width: 10px; height: 10px; background: #FFCF25; border-radius: 9999px"></div>
                            <div
                                style="color: #222222; font-size: 14px; font-family: DM Sans; font-weight: 400; line-height: 18px; word-wrap: break-word">Alumno
                                cambiado de curso/nivel/grupo</div>
                        </div>
                        <div
                            style="justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                            <div
                                style="width: 10px; height: 10px; background: #E70F0F; border-radius: 9999px"></div>
                            <div
                                style="color: #222222; font-size: 14px; font-family: DM Sans; font-weight: 400; line-height: 18px; word-wrap: break-word">No
                                presentado</div>
                        </div>
                        <div
                            style="justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                            <div
                                style="width: 10px; height: 10px; background: #C7D0D3; border-radius: 9999px"></div>
                            <div
                                style="color: #222222; font-size: 14px; font-family: DM Sans; font-weight: 400; line-height: 18px; word-wrap: break-word">Día
                                a venir</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cardgroup" *ngIf="courseFormGroup.controls['id'] && !detail"
        style="background-color: #2F3844;flex-direction: column;flex-wrap: nowrap;gap: 8px;">
        <p
            style="display: flex;flex-wrap: nowrap;
         color: white;
         font-size: 16px;
         font-family: Dinamit;
         font-weight: 500;
         line-height: 20px;
         word-wrap: break-word">
            {{"Curso #" +
            ("00000"+courseFormGroup.controls['id'].value).slice(-5) |
            translate}}
            <img src="assets/icons/icon/edit.png"
                (click)="open.emit(courseFormGroup.controls['id'].value)"
                class="button icon24"
                style="margin-left: auto;">
            <img src="assets/icons/icon/cancel.png" (click)="close.emit()"
                class="button icon24">
        </p>
        <div
            style="width: 100%; height: 100%; border: 1px #C7D0D3 solid;margin: 4px 0;"></div>
        <p style="
        color: white;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        word-wrap: break-word">
            {{"Registrado: "| translate}}
            <span style="font-weight: 600;">
                {{ DateISO(courseFormGroup.controls['created_at'].value)}}
            </span>
        </p>
        <p *ngIf="courseFormGroup.controls['user'].value" style="
        color: white;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        word-wrap: break-word">
            {{courseFormGroup.controls['user'].value}}
        </p>
        <div style="display: flex; gap: 8px;">
            <div *ngIf="courseFormGroup.controls['active'].value"
                style="padding-left: 8px; padding-right: 8px; padding-top: 4px; padding-bottom: 4px; background: #B2EFAD; border-radius: 3px; justify-content: center; align-items: center; gap: 10px; display: inline-flex">
                <div
                    style="color: #075800; font-size: 12px; font-weight: 600; line-height: 16px; word-wrap: break-word">ACTIVO</div>
            </div>
            <div *ngIf="courseFormGroup.controls['online'].value"
                style="padding-left: 8px; padding-right: 8px; padding-top: 4px; padding-bottom: 4px; background: #B2EFAD; border-radius: 3px; justify-content: center; align-items: center; gap: 10px; display: inline-flex">
                <div
                    style="color: #075800; font-size: 12px; font-weight: 600; line-height: 16px; word-wrap: break-word">ONLINE</div>
            </div>
        </div>
    </div>
    <div class="cardgroup" style="flex-wrap: nowrap;align-items: center;">
        <img
            [src]="courseFormGroup.controls['icon'].value || 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='"
            [class]="courseFormGroup.controls['icon'].value?'':'emptyImg1'"
            class="sport_icon noselected"
            [style.backgroundColor]="courseFormGroup.controls['course_type'].value===1?'#E5962A':courseFormGroup.controls['course_type'].value===2?'#2FAA41':courseFormGroup.controls['course_type'].value===3?'#0AB0BC':''"
            style="max-width: none;">
        <div style="width: max-content;"
            [style.minWidth]="courseFormGroup.controls['id']?'':'calc(100% - 60px)'">
            <p
                style="font-weight: 600; line-height: 18px; min-height: 12px;color: var(--color-dark1);font-size: 14px;"
                [class]="courseFormGroup.controls['course_name'].value?'':'emptyText'">
                {{courseFormGroup.controls['course_name'].value}}
            </p>
            <p
                style="line-height: 18px; min-height: 12px;margin-top: 5px;
                color: var(--color-dark1);
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                word-wrap: break-word"
                [class]="courseFormGroup.controls['course_type'].value?'':'emptyText'">
                {{courseFormGroup.controls['course_type'].value===1?'Curso colectivo flex':courseFormGroup.controls['course_type'].value===2?'Curso privado flex':courseFormGroup.controls['course_type'].value===3?'Actividad':''
                | translate}}
            </p>
        </div>
        <div *ngIf="courseFormGroup.controls['id'] && !detail"
            style="margin-left: auto;">
            <p style="
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            text-align: right;
            color: var(--color-dark1);
            ">
                {{courseFormGroup.controls['price'].value}}{{courseFormGroup.controls['price'].value?'&nbsp;CHF':''}}
            </p>
        </div>
        <img src="assets/icons/icon/edit2.png" alt
            style="margin-left: auto;width: 24px;"
            *ngIf="detail" (click)="edit.emit(0)"
            class="editbutton icon24">
    </div>
    <div class="cardgroup">
        <div style="width: 100%;display: flex;">
            <img
                [src]="courseFormGroup.controls['img'].value || 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='"
                class="Img1"
                [class]="courseFormGroup.controls['img'].value?'':'emptyImg1'"
                style="margin-right: 10px;height: 68px;width: 95px;max-width: none;">
            <div style="width: 100%;">
                <p> {{'RESUMEN' | translate }} </p>
                <p style="color:var(--color-dark1);"
                    [class]="courseFormGroup.controls['summary'].value?'':'emptyText'"
                    [innerHTML]="courseFormGroup.controls['summary'].value">
                </p>
            </div>
            <div>
                <img src="assets/icons/icon/edit2.png" alt
                    *ngIf="detail" (click)="edit.emit(1)"
                    class="editbutton icon24">
            </div>
        </div>
        <div style="width: 100%">
            <p> {{'DESCRIPCIÓN' | translate }} </p>
            <p
                [class]="courseFormGroup.controls['description'].value?'':'emptyText'"
                style="color:var(--color-dark1);"
                [innerHTML]="courseFormGroup.controls['description'].value">
            </p>
        </div>
        <div style="width: calc(50% - 10px);">
            <p> {{'PRECIO POR FECHA' | translate }} </p>
            <p [class]="courseFormGroup.controls['price'].value?'':'emptyText'"
                style="color:var(--color-dark1); font-weight: 400;">
                {{courseFormGroup.controls['price'].value}}{{courseFormGroup.controls['price'].value?'&nbsp;CHF':''}}
            </p>
        </div>
        <div style="width: calc(50% - 10px);">
            <p> {{'PARTICIPANTES MAX.' | translate }} </p>
            <p style="color:var(--color-dark1); font-weight: 400;font-size: 12px;"
                [class]="courseFormGroup.controls['participants'].value?'':'emptyText'">
                {{courseFormGroup.controls['participants'].value}}
            </p>
        </div>
        <div style="width: calc(50% - 10px);"
            *ngIf="courseFormGroup.controls['course_type'].value>1">
            <p> {{'EDAD MIN.' | translate }} </p>
            <p style="color:var(--color-dark1);font-size: 12px;"
                [class]="courseFormGroup.controls['age_min'].value?'':'emptyText'">
                {{courseFormGroup.controls['age_min'].value}}
            </p>
        </div>
        <div style="width: calc(50% - 10px);"
            *ngIf="courseFormGroup.controls['course_type'].value>1">
            <p> {{'EDAD MAX.' | translate }} </p>
            <p style="color:var(--color-dark1); font-size: 12px;"
                [class]="courseFormGroup.controls['age_max'].value?'':'emptyText'">
                {{courseFormGroup.controls['age_max'].value}}
            </p>
        </div>
    </div>
    <div class="cardgroup" style="gap: 10px;">
        <div style="display: flex;width: 100%;column-gap: 10px;">
            <div style="width: 100%">
                <p> {{'RESERVABLE DESDE' | translate }} </p>
                <p style="color:var(--color-dark1); font-size: 12px;"
                    [class]="courseFormGroup.controls['reserve_from'].value?'':'emptyText'">
                    {{DateISO(courseFormGroup.controls['reserve_from'].value)}}
                </p>
            </div>
            <div style="width: 100%">
                <p> {{'RESERVABLE HASTA' | translate }} </p>
                <p style="color:var(--color-dark1);font-size: 12px;"
                    [class]="courseFormGroup.controls['reserve_to'].value?'':'emptyText'">
                    {{DateISO(courseFormGroup.controls['reserve_to'].value)}}
                </p>
            </div>
            <div style="width: 100%"
                *ngIf="courseFormGroup.controls['course_type'].value>1">
                <p> {{'DUR. MIN.' | translate }} </p>
                <p style="color:var(--color-dark1);font-size: 12px;"
                    [class]="courseFormGroup.controls['duration_min'].value?'':'emptyText'">
                    {{courseFormGroup.controls['duration_min'].value}}
                </p>
            </div>
            <div>
                <img src="assets/icons/icon/edit2.png" alt
                    *ngIf="detail" (click)="edit.emit(3)"
                    class="editbutton icon24">
            </div>
        </div>

        <div style="width: 100%;"
            *ngIf="courseFormGroup.controls['reserve_date'].value.length>0">
            <div style="display: flex;">
                <img src="assets/icons/icon/calendar.png" class="icon24">
                <p>
                    {{'FECHA Y HORA' |
                    translate }}
                </p>
            </div>
            <p *ngIf="!courseFormGroup.controls['reserve_date'].value"
                class="emptyText">
            </p>
            <p
                *ngFor="let item of courseFormGroup.controls['reserve_date'].value"
                style="font-size: 14px;text-align-last: justify;">
                <span style="color:var(--color-dark1);">{{DateISO(item.date)}}</span>
                <span style="color:var(--color-dark1);"
                    *ngIf="courseFormGroup.controls['course_type'].value>1 && item.date_end">-{{DateISO(item.date_end)}}
                </span>
                <span> {{item.hour_start}}</span>
                <span
                    *ngIf="courseFormGroup.controls['course_type'].value>1">-{{item.hour_end}}</span>
                <span
                    *ngIf="item.Duracion && courseFormGroup.controls['price'].value>0">
                    {{courseFormGroup.controls['price'].value *
                    item.Duracion || 0}}&nbsp;CHF
                </span>
                <span style="color:var(--color-dark1);"
                    *ngIf="!item.Duracion && courseFormGroup.controls['price'].value>0">
                    {{(courseFormGroup.controls['price'].value *
                    (item.hour_end.split(":")[0]+item.hour_end.split(":")[1]/60-item.hour_start.split(":")[0]+item.hour_start.split(":")[1]/60)).toFixed(2)}}CHF
                </span>
            </p>
        </div>
        <div style="width: 100%; color:var(--color-dark1);font-weight: 400;"
            *ngFor="let item of courseFormGroup.controls['discount'].value">
            · Reducción {{item.reduccion}}% reservando
            {{item.day}} fechas
        </div>
        <div *ngIf="courseFormGroup.controls['settings'].value"
            style="color: var(--Dark-01, #222);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;">
            <ng-container
                style="width: 100%; color:var(--color-dark1);font-weight: 400;"
                *ngFor="let item of week">
                <ng-container
                    *ngIf="courseFormGroup.controls['settings'].value.weekDays[item]">
                    {{item | translate}}
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="cardgroup"
        *ngIf="count(courseFormGroup.controls['levelGrop'].value,'active') && !detail">
        <div
            style="width: 100%;display: flex;gap: 8px;flex-wrap: wrap;">
            <div style="width: 100%;">
                <p> {{'NIVELES' | translate }} </p>
            </div>
            <vex-course-detail-nivel style="width: 100%;"
                [courseFormGroup]="courseFormGroup"></vex-course-detail-nivel>
        </div>
    </div>
    <div class="cardgroup"
        *ngIf="detail">
        <div
            style="width: 100%;display: flex;gap: 18px;flex-wrap: wrap;">
            <div style="width: 100%;">
                <p> {{'IDIOMAS' | translate }}
                    <img src="assets/icons/icon/edit2.png" alt
                        *ngIf="detail" (click)="edit.emit(5)"
                        class="editbutton icon24">
                </p>
            </div>
            <div style="width: 100%; display: flex;gap: 20px;">
                <p style="height: 24px;
                color: var(--color-dark1);
                 font-size: 14px;
                 font-weight: 600;
                 line-height: 18px;
                 word-wrap: break-word">
                    Francés
                    <mat-icon class="check">check</mat-icon>
                </p>
                <p style="height: 24px;
                color: var(--color-dark1);
                 font-size: 14px;
                 font-weight: 600;
                 line-height: 18px;
                 word-wrap: break-word">
                    Inglés
                    <mat-icon class="check">check</mat-icon>
                </p>
                <p style="height: 24px;
                color: var(--color-dark1);
                 font-size: 14px;
                 font-weight: 600;
                 line-height: 18px;
                 word-wrap: break-word">
                    Aleman
                    <mat-icon class="check">check</mat-icon>
                </p>
                <p style="height: 24px;
                color: var(--color-dark1);
                 font-size: 14px;
                 font-weight: 600;
                 line-height: 18px;
                 word-wrap: break-word">
                    Español
                    <mat-icon class="check">check</mat-icon>
                </p>
                <p style="height: 24px;
                color: var(--color-dark1);
                 font-size: 14px;
                 font-weight: 600;
                 line-height: 18px;
                 word-wrap: break-word">
                    Italiano
                    <mat-icon class="check">check</mat-icon>
                </p>
            </div>

        </div>
    </div>
    <div class="cardgroup"
        *ngIf="courseFormGroup.controls['extras'].value.length>0 || (detail && count(courseFormGroup.controls['levelGrop'].value,'active'))">
        <div
            style="width: 100%;display: flex;gap: 18px;flex-wrap: wrap;">
            <div style="width: 100%;">
                <p> {{'EXTRAS' | translate }}
                    <img src="assets/icons/icon/edit2.png" alt
                        *ngIf="detail" (click)="edit.emit(4)"
                        class="editbutton icon24">
                </p>
            </div>
            <ng-container
                *ngFor="let item of courseFormGroup.controls['extras'].value">
                <div style="width: 100%; display: flex;">
                    <span style="
                    color: var(--color-dark1);
                     font-size: 14px;
                     font-weight: 600;
                     line-height: 18px;
                     word-wrap: break-word">
                        {{item.name.slice(0,20)}}
                    </span>
                    <span style="
                    color: var(--color-dark1);
                     font-size: 14px;
                     font-weight: 300;
                     line-height: 18px;
                     word-wrap: break-word;
                     margin-left: auto;">
                        {{item.price || 0}} CHF/día
                    </span>
                </div>
            </ng-container>
            <ng-container
                *ngIf="courseFormGroup.controls['extras'].value.length===0">
                <div>
                    <button
                        style="margin-top: 0px !important;"
                        color="primary"
                        mat-button
                        type="button"
                        class="client-button">
                        <mat-icon>add</mat-icon>
                        {{'Añadir extra' | translate }}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

</div>
