<div
    class="outside"
    [style]="imagePreviewUrl?'':'border: 1px #C7D0D3;border-style: dashed;padding: 20px;border-radius: 8px;'">
    <label class="btn-1" for="file" *ngIf="!imagePreviewUrl">
        <img src="assets/icons/icon/upload.png"  style="width: 100%;
        height: 30px;
        font-size: 30px;
        object-fit: scale-down;">
        <p style="font-size: 12px">{{'image_max' | translate}}</p>
        <p style="font-size: 12px">{{'image_format' |
            translate}}</p>
        <p style="font-size: 12px">{{'image_recomendation' |
            translate}}</p>
    </label>
    <div class="image-container" style="float: left;width: 50%;"
        *ngIf="imagePreviewUrl">
        <img
            [src]="imagePreviewUrl"
            alt="Image Preview" width="400" height="290"
            style="width: 400px;height: 290px;object-fit: contain;margin:0 auto">
    </div>
    <label for="file" class="custom-file-upload"
        *ngIf="imagePreviewUrl">
        {{"Change image" | translate}}
    </label>
    <input type="file" style="display: none;" (change)="onFileChanged($event)"
        id="file" />
</div>